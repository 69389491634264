export const getCurrentTime = (): string => {
    const date = new Date();
    let h = date.getHours() as number | string;
    let m = date.getMinutes() as number | string;

    h = (h < 10) ? "0" + h : h;
    m = (m < 10) ? "0" + m : m;

    return `${h}:${m}`;
}

export const getFullFormattedDate = (date: Date): string => {
    let month = '' + (date.getMonth() + 1);
    let day = '' + date.getDate();
    const year = date.getFullYear();

    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }

    return `${day}/${month}/${year}`;
}

export const getDayName = (date: Date): string => {
    return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()];
}

export const getTimeDifferenceInMinutes = (from: Date, to: Date): number => {
    return Math.floor(((to.getTime() / 1000) - (from.getTime() / 1000)) / 60);
}

export const getUsTimeNotation = (time: Date): string => {
    return time.toLocaleString('en-US', { hour: 'numeric', hour12: true }).replace(' ', '');
}
