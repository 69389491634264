export interface IDepartureLine {
    line: ILine;
    directions: IDepartureDirection[];
}

export interface IDepartureDirection {
    direction: string;
    departures: IDeparture[];
}

export interface IDeparture {
    direction: string;
    platform?: string | null;
    departing_at: Date;
    planned_departure_at: Date;
    line: ILine;
    remarks: IRemark[];
}

export enum LineType {
    BUS = 'bus',
    SBAHN = 'suburban',
    UBAHN = 'subway',
    TRAM = 'tram',
}

export interface ILine {
    id: string;
    type: LineType;
    name: string;
}

export interface IRemark {
    summary: string;
    text: string;
}
