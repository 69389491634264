import React from 'react';

export const NoResults = () => (
    <div className="lines-container no-results">
        <div>
            No departures have been found :(
            <br />
            Retrying in a minute!
        </div>
    </div>
)
