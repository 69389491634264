import React from 'react';

export const LogoLoadingPlaceholders = () => {
    return (
        <div className="logo-container">
            <div className="animated-background logo-badge" />
            <div className="animated-background location-name" />
            <div className="animated-background location-address" />
        </div>
    );
}
