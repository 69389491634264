import React, {useEffect, useState} from 'react';
import {IDepartureLine} from "../../models/departure";
import {DepartureLine} from "./DepartureLine";
import {fetchDepartures} from "../../stores/directions";
import {LoadingPlaceholders} from "./LoadingPlaceholders";
import {groupDeparturesByLine} from "../../helpers/departures";
import {NoResults} from "./NoResults";

const getAdditionalContainerClass = (size: number): string => {
    if (size < 5) {
        return 'lines-container-super-wide';
    }

    if (size > 6) {
        return 'lines-container-outer-smaller';
    }

    return '';
};

export const Directions = () => {
    const [departureLines, setDepartureLines] = useState<IDepartureLine[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            setLoading(true);

            const data = await fetchDepartures();

            setDepartureLines(groupDeparturesByLine(data));
            setLoading(false);
        })();
    }, [setLoading, setDepartureLines]);

    if (loading) {
        return (
            <LoadingPlaceholders />
        );
    }

    if (!departureLines.length) {
        return (
            <NoResults />
        );
    }

    return (
        <div className={`lines-container ${getAdditionalContainerClass(departureLines.length)}`}>
            {departureLines.map(departureLine => <DepartureLine departureLine={departureLine} key={departureLine.line.id} />)}
        </div>
    );
}
