import React from 'react';
import {Directions} from "./Directions/Directions";
import {SideBar} from "./SideBar/SideBar";
import "./styles.css"
import {Main} from "./App/Main";

export const App = () => {
    return (
        <Main>
            <div className="left-col">
                <Directions />
            </div>

            <div className="right-col">
                <SideBar />
            </div>
        </Main>
    );
}
