import {IDeparture, IDepartureLine} from "../models/departure";

const departureLinesSorter = (a: IDepartureLine, b: IDepartureLine) => {
    const typeA = a.line.name.toUpperCase();
    const typeB = b.line.name.toUpperCase();
    if (typeA < typeB) {
        return -1;
    }
    if (typeA > typeB) {
        return 1;
    }

    const nameA = a.line.name.toUpperCase();
    const nameB = b.line.name.toUpperCase();
    if (nameA < nameB) {
        return -1;
    }
    if (nameA > nameB) {
        return 1;
    }

    return 0;
};


const directionSorter = (a: IDeparture, b: IDeparture) => {
    const directionA = a.direction;
    const directionB = b.direction;

    if (directionA < directionB) {
        return -1;
    }
    if (directionA > directionB) {
        return 1;
    }

    return 0;
};

const departureTimesSorter = (a: IDeparture, b: IDeparture) => {
    const timeA = a.departing_at;
    const timeB = b.departing_at;

    if (timeA < timeB) {
        return -1;
    }
    if (timeA > timeB) {
        return 1;
    }

    return 0;
};

export const groupDeparturesByLine = (departures: IDeparture[]) : IDepartureLine[] => {
    const tmp = {} as any;

    departures.forEach(item => {
        if (tmp[item.line.id] === undefined) {
            tmp[item.line.id] = {
                line: item.line,
                directions: {},
            };
        }

        if (tmp[item.line.id].directions[item.direction] === undefined) {
            tmp[item.line.id].directions[item.direction] = {
                direction: item.direction,
                departures: [],
            };
        }

        tmp[item.line.id].directions[item.direction].departures.push(item);
    });

    return Object.values(tmp).map((item: any) => {
        item.directions = Object.values(item.directions).map((dep: any) => {
            dep.departures = dep.departures.sort(departureTimesSorter);

            return dep;
        }).sort(directionSorter);
        return item;
    }).sort(departureLinesSorter);
};
