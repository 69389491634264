import React, {useEffect, useState} from 'react';
import {IWeatherForecast} from "../../models/weather";
import {fetchWeatherForecast} from "../../stores/weather";
import {Forecast} from "./Forecast";
import {LoadingPlaceholders} from "./LoadingPlaceholders";

export const Weather = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [weatherForecast, setWeatherForecast] = useState<IWeatherForecast[]>([]);

    useEffect(() => {
        (async () => {
            setLoading(true);

            const data = await fetchWeatherForecast();

            setWeatherForecast(data);
            setLoading(false);
        })();
    }, [setLoading, setWeatherForecast]);

    if (loading) {
        return (
            <LoadingPlaceholders />
        );
    }

    return (
        <div className="weather-outer-container">
            <div className="weather-container">
                {weatherForecast.map((forecast, index) => <Forecast index={index} forecast={forecast} key={index} />)}
            </div>
        </div>
    );
}
