import React from 'react';

const LoadingPlaceholder = () => (
    <div className="line-container-outer">
        <div className="line-container">
            <div className="line-header">
                <div className="line-icon">
                    <div className="animated-background" />
                </div>
                <div className="animated-background line-title" />
            </div>

            <div className="departures-container">
                <div className="departure-container">
                    <div className="departure-direction-container">
                        <div className="departure-direction-title">Direction</div>
                        <div className="animated-background departure-direction" />
                    </div>

                    <div className="departure-time animated-background" />
                </div>

                <div className="departure-container">
                    <div className="departure-direction-container">
                        <div className="departure-direction-title">Direction</div>
                        <div className="animated-background departure-direction" />
                    </div>

                    <div className="departure-time animated-background" />
                </div>
            </div>
        </div>
    </div>
);

export const LoadingPlaceholders = () => (
    <div className="lines-container">
        <LoadingPlaceholder />
        <LoadingPlaceholder />
        <LoadingPlaceholder />
        <LoadingPlaceholder />
        <LoadingPlaceholder />
        <LoadingPlaceholder />
    </div>
)
