import React from 'react';
import {DepartingTimes} from "./DepartingTimes";
import {IDepartureDirection} from "../../models/departure";

interface IDepartureProps {
    direction: IDepartureDirection;
}

export const Direction = ({direction}: IDepartureProps) => {
    if (direction.departures.length === 0) {
        return null;
    }

    return (
        <div className="departure-container">
            <div className="departure-direction-container">
                <div className="departure-direction-title">Direction</div>
                <div className="departure-direction">{direction.direction}</div>

                {/*
                {!!direction.platform && <div className="departure-platform-container">
                    <div className="departure-direction-title">PLATFORM</div>
                    <div className="departure-platform">{direction.platform}</div>
                </div>}
                */}
            </div>

            <div className="departure-times">
                <DepartingTimes departures={direction.departures} />
            </div>
        </div>
    );
}
