import {fetchGet} from "../services/api";
import {IDeparture} from "../models/departure";

export const fetchDepartures = (): Promise<IDeparture[]> => {
    return fetchGet( '/departures')
        .then(data => {
            return data.map((item: any) => {
                item.departing_at = new Date(item.departing_at);
                item.planned_departure_at = new Date(item.planned_departure_at);

                return item;
            });
        });
};
