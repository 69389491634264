import React, {useState} from 'react';
import {getCurrentTime, getDayName, getFullFormattedDate} from "../../helpers/datetime";

export const CurrentDate = () => {
    const today = new Date();
    const [time, setTime] = useState<string>(getCurrentTime());

    setTimeout(() => {
        setTime(getCurrentTime());
    }, 1000);

    return (
        <div className="date-and-time-container">
            <div className="time">{time}</div>
            <div className="date">{getDayName(today)}, {getFullFormattedDate(today)}</div>
        </div>
    );
}
