import {API_HOST, DEFAULT_ACCESS_TOKEN} from "../config/config";

const API_V1_BASE = `${API_HOST}/api/v1`;

export const fetchGet = (url: string, query: any = {}, props: any = {}): Promise<any> => {
    return fetch(API_V1_BASE + url + '?' + new URLSearchParams(query), {
        mode: 'cors',
        headers: {
            'Authorization': `Bearer ${DEFAULT_ACCESS_TOKEN}`,
        },
        ...props
    })
        .then(response => response.json());
};
