import React from 'react';
import {Logo} from "./Logo";
import {CurrentDate} from "./CurrentDate";
import {Weather} from "../Weather/Weather";

export const SideBar = () => {
  return (
      <div className="side-bar-container">
          <Logo />
          <CurrentDate />
          <Weather />
      </div>
  );
}
