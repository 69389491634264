import React, {useState} from 'react';
import {IDeparture} from "../../models/departure";
import {getTimeDifferenceInMinutes} from "../../helpers/datetime";

interface IDepartingTimeProps {
    departure: IDeparture;
}

interface IDepartingTimesProps {
    departures: IDeparture[];
}

export const DepartingTime = ({departure}: IDepartingTimeProps) => {
    const [departingTime, setDepartingTime] = useState<number>(getTimeDifferenceInMinutes(new Date(), departure.departing_at));

    if (departingTime === 0) {
        return (
            <>now</>
        );
    }

    return (
        <>
            <strong>{departingTime}</strong> min
        </>
    )
}

export const DepartingTimes = ({departures}: IDepartingTimesProps) => {
    const nonPastDepartures = departures.filter(item => {
        return getTimeDifferenceInMinutes(new Date(), item.departing_at) >= 0;
    }).slice(0, 5);
    const length = nonPastDepartures.length - 1;

    return (
        <>
            {nonPastDepartures.map((departure, index) => {
                return (
                    <>
                        <DepartingTime departure={departure} key={index} />

                        {index !== length && ', '}
                    </>
                )
            })}
        </>
    )
}
