import React from 'react';

export const LoadingPlaceholders = () => {
    return (
        <div className="weather-outer-container">
            <div className="weather-container">
                <div className="animated-background forecast-item" />
                <div className="animated-background forecast-item" />
                <div className="animated-background forecast-item" />
                <div className="animated-background forecast-item" />
            </div>
        </div>
    );
}
