// https://gist.github.com/tbranyen/62d974681dea8ee0caa1
import {WEATHER_ICONS} from "../config/weather";

export const getWeatherIcon = (code: number): string => {
    let icon = (WEATHER_ICONS as any)[code].icon;

    // If we are not in the ranges mentioned above, add a day/night prefix.
    if (!(code > 699 && code < 800) && !(code > 899 && code < 1000)) {
        icon = `day-${icon}`;
    }

    return `wi-${icon}`;
}
