import {ILine, LineType} from "../models/departure";

export const getIconByType = (line: ILine): string => {
    switch (line.type) {
        case LineType.BUS: return 'bus';
        case LineType.SBAHN: return 'sbahn';
        case LineType.UBAHN: return 'ubahn';
        case LineType.TRAM: return 'tram';
    }

    return '';
}
