import React from 'react';
import {Direction} from "./Direction";
import {IDepartureLine} from "../../models/departure";
import {getIconByType} from "../../helpers/directions";

interface IDepartureLineProps {
    departureLine: IDepartureLine;
}

export const DepartureLine = ({departureLine}: IDepartureLineProps) => {
    const icon = getIconByType(departureLine.line);

    return (
        <div className="line-container-outer">
            <div className="line-container">
                <div className="line-header">
                    <div className="line-icon">
                        <img src={`/static/icons/transportation/${icon}.svg`} alt={departureLine.line.name} />
                    </div>
                    <h5 className="line-title">{departureLine.line.name}</h5>
                </div>

                <div className="departures-container">
                    {departureLine.directions.map((direction, index) => <Direction direction={direction} key={index} />)}
                </div>
            </div>
        </div>
    );
}
