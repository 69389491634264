import {IWeatherForecast} from "../models/weather";
import {fetchGet} from "../services/api";

export const fetchWeatherForecast = (): Promise<IWeatherForecast[]> => {
    return fetchGet( '/weather/forecast')
        .then(data => {
            return data.map((item: any) => {
                item.date = new Date(item.date);

                return item;
            });
        });
};
