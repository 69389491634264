import React from 'react';

interface IMainProps {
    children: React.ReactNode;
}

export const Main = ({children}: IMainProps) => {
    return (
        <div className="main">
            {children}
        </div>
    );
}
