import React from 'react';
import {IWeatherForecast} from "../../models/weather";
import {getWeatherIcon} from "../../helpers/weather";
import {getUsTimeNotation} from "../../helpers/datetime";

interface IForecastProps {
    index: number;
    forecast: IWeatherForecast;
}

export const Forecast = ({index, forecast}: IForecastProps) => {
    const icon = getWeatherIcon(forecast.weather.id);

    return (
        <div className="forecast-item">
            <div className="forecast-time-and-temperature">
                <div className="forecast-time">
                    {index === 0 ? 'Now' : getUsTimeNotation(forecast.date)}
                </div>
                <div>
                    <div className="forecast-temperature">{forecast.temperature} °C</div>
                    <div className="forecast-feels-like">Feels like <span>{forecast.feels_like} °C</span></div>
                    <div className="forecast-wind">W {forecast.wind_speed} km/h</div>
                </div>
            </div>

            <div className="forecast-details">
                <img src={`static/icons/weather/${icon}.svg`} alt={forecast.weather.name} />
                <div className="forecast-description">{forecast.weather.description}</div>
            </div>
        </div>
    );
}
