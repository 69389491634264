import React, {useEffect, useState} from 'react';
import {IUser} from "../../models/user";
import {fetchMe} from "../../stores/user";
import {LogoLoadingPlaceholders} from "./LogoLoadingPlaceholders";

export const Logo = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useState<IUser>();

    useEffect(() => {
        (async () => {
            setLoading(true);

            const data = await fetchMe();

            setUser(data);
            setLoading(false);
        })();
    }, [setLoading, setUser]);

    if (loading) {
        return (
            <LogoLoadingPlaceholders />
        );
    }

    return (
        <div className="logo-container">
            <div className="logo-badge">
                {user?.name[0]}
            </div>
            <div className="location-name">{user?.name}</div>
            <div className="location-address">{user?.location}</div>
        </div>
    );
}
